import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import useTranslation from '../../../translations';

const Outer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const t = useTranslation();
  return (
    <Wrapper>
      <Main>
        <Content>
          <Logo data-testid="logo" />
          {children}
        </Content>
      </Main>
      <Footer>
        <Text>
          <Trans
            t={t}
            i18nKey="SIGNUP.SUPPORT"
            components={{
              tag: <Link href="mailto:support@philipa.tech" />,
            }}
          />
        </Text>
        <Text>© 2022 Philipa</Text>
      </Footer>
    </Wrapper>
  );
};

export default Outer;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #21232c -4%, #504e5e 104%);
`;

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  max-height: 360px;
  padding: 30px;
  padding-top: 50px;
  border-radius: 24px;
  background-image: linear-gradient(to right, #21232c -4%, #3a3943 104%);
`;

const Logo = styled.div`
  width: 100%;
  height: 70px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url('/assets/shared/philipa-logo.png');
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url('/assets/shared/philipa-logo@2x.png');
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 700px;
  padding-bottom: 20px;
  gap: 11px;
`;

const Text = styled.div`
  color: ${(props) => props.theme.colors.white50};
  font-size: 13px;
  line-height: 2;
`;

const Link = styled.a`
  color: ${(props) => props.theme.colors.white87};
  font-weight: bold;
  &:focus,
  &:hover,
  &:active {
    color: ${(props) => props.theme.colors.white87};
    text-decoration: underline;
  }
`;
