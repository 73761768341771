import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMsal } from '@azure/msal-react';
import { useLocation } from 'react-router-dom';
import { InteractionStatus } from '@azure/msal-browser';
import { authRequest, clearAuthData } from '../../auth/msalInstance';
import { LOGIN_REDIRECT_URL_KEY } from '../../constants';
import useTranslation from '../../translations';
import { MsButton, Outer } from './Common';

const Login: React.FC = () => {
  const t = useTranslation();
  const { inProgress, instance } = useMsal();
  const { state } = useLocation<{ redirect?: string } | undefined>();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    window.sessionStorage.setItem(
      LOGIN_REDIRECT_URL_KEY,
      state?.redirect || '/'
    );
  }, [state]);

  const loginCallback = useCallback(async () => {
    try {
      await instance.loginRedirect({
        ...authRequest,
      });
    } catch {
      clearAuthData();
      setError(true);
    }
  }, []);

  return (
    <Outer>
      <Title>{t('LOGIN.TITLE')}</Title>
      <MsButton
        loading={inProgress === InteractionStatus.Login}
        label={t('LOGIN.LOGIN_WITH_MS')}
        style={{ margin: '13px 0 40px 0' }}
        onClick={loginCallback}
      />
      {error && <ErrorMsg>{t('LOGIN.ERROR')}</ErrorMsg>}
    </Outer>
  );
};

export default Login;

const Title = styled.div`
  margin: 20px 0 33px;
  font-size: 22px;
  line-height: 1.36;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white100};
`;

const ErrorMsg = styled.div`
  margin: -35px 0 20px 0;
  color: ${(props) => props.theme.colors.error};
  text-align: center;
  white-space: pre;
`;
