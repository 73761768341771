import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Session } from '../../types';
import SHARED_ROUTES from '../../utils/routing';

const BASE_URLS = {
  DASHBOARD: 'app-ipos-dashboard.azurewebsites.net',
  TSD: 'app-ipos-tsd.azurewebsites.net',
  TSD_OLD: 'tsd.digital.attorney',
  EVALUATOR: 'app-ipos-eval.azurewebsites.net',
  LIFTOFF: 'app-ipos-liftoff.azurewebsites.net',
  OAH: 'app-ipos-oah.azurewebsites.net',
  DDUDE: 'app-ipos-ddude.azurewebsites.net',
  COMPETITOR: 'app-ipos-comp.azurewebsites.net',
};

const BASE_URL_MAP = {
  [BASE_URLS.DASHBOARD]: 'philipa.app',
  [BASE_URLS.TSD]: 'tsd.philipa.app',
  [BASE_URLS.TSD_OLD]: 'tsd.philipa.app',
  [BASE_URLS.EVALUATOR]: 'eval.philipa.app',
  [BASE_URLS.LIFTOFF]: 'liftoff.philipa.app',
  [BASE_URLS.OAH]: 'oah.philipa.app',
  [BASE_URLS.DDUDE]: 'ddude.philipa.app',
  [BASE_URLS.COMPETITOR]: 'comp.philipa.app',
};

const SCHAEFFLER_URL_MAP = {
  [BASE_URLS.DASHBOARD]: 'schaeffler.philipa.app',
  [BASE_URLS.TSD]: 'tsd-schaeffler.philipa.app',
  [BASE_URLS.TSD_OLD]: 'tsd-schaeffler.philipa.app',
  [BASE_URLS.EVALUATOR]: 'eval-schaeffler.philipa.app',
  [BASE_URLS.LIFTOFF]: 'liftoff-schaeffler.philipa.app',
  [BASE_URLS.OAH]: 'oah-schaeffler.philipa.app',
  [BASE_URLS.DDUDE]: 'ddude-schaeffler.philipa.app',
  [BASE_URLS.COMPETITOR]: 'comp-schaeffler.philipa.app',
};

const BAYER_URL_MAP = {
  [BASE_URLS.DASHBOARD]: 'bayer.philipa.app',
  [BASE_URLS.TSD]: 'tsd-bayer.philipa.app',
  [BASE_URLS.TSD_OLD]: 'tsd-bayer.philipa.app',
  [BASE_URLS.EVALUATOR]: 'eval-bayer.philipa.app',
  [BASE_URLS.LIFTOFF]: 'liftoff-bayer.philipa.app',
  [BASE_URLS.OAH]: 'oah-bayer.philipa.app',
  [BASE_URLS.DDUDE]: 'ddude-bayer.philipa.app',
  [BASE_URLS.COMPETITOR]: 'comp-bayer.philipa.app',
};

const MERCK_URL_MAP = {
  [BASE_URLS.DASHBOARD]: 'merck.philipa.app',
  [BASE_URLS.TSD]: 'tsd-merck.philipa.app',
  [BASE_URLS.TSD_OLD]: 'tsd-merck.philipa.app',
  [BASE_URLS.EVALUATOR]: 'eval-merck.philipa.app',
  [BASE_URLS.LIFTOFF]: 'liftoff-merck.philipa.app',
  [BASE_URLS.OAH]: 'oah-merck.philipa.app',
  [BASE_URLS.DDUDE]: 'ddude-merck.philipa.app',
  [BASE_URLS.COMPETITOR]: 'comp-merck.philipa.app',
};

const SCHAEFFLER_GROUP_ID = 'd1df9556-b9a9-4404-b10f-9dba2a8056a8';
const BAYER_GROUP_ID = '56f562fc-3956-450d-a45b-633ad2693171';
const MERCK_GROUP_ID = '64deec37-c4de-4c3b-a855-82a7b25a64e7';

const RedirectHandler: React.FC<{ session?: Session }> = ({ session }) => {
  const location = useLocation();

  useEffect(() => {
    if (!session) {
      return;
    }

    const { ownerGroupId } = session;
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);

    // Skip redirect if the user is on the login route
    if (url.pathname === SHARED_ROUTES.LOGIN.path) {
      return;
    }

    // Extract the origin
    const origin = url.origin.replace('https://', '');

    // Check if old origin
    const oldOrigins = Object.values(BASE_URLS);
    const isOldOrigin = oldOrigins.includes(origin);

    // Skip redirect if not old origin
    if (!isOldOrigin) {
      return;
    }

    let newOrigin;

    // Determine new origin origin based on user group
    switch (ownerGroupId) {
      case SCHAEFFLER_GROUP_ID:
        newOrigin = SCHAEFFLER_URL_MAP[origin];
        break;
      case BAYER_GROUP_ID:
        newOrigin = BAYER_URL_MAP[origin];
        break;
      case MERCK_GROUP_ID:
        newOrigin = MERCK_URL_MAP[origin];
        break;
      default:
        newOrigin = BASE_URL_MAP[origin];
        break;
    }

    if (newOrigin) {
      // Preserve the rest of the URL
      const restOfUrl = url.pathname + url.search + url.hash;
      const newUrl = `https://${newOrigin}${restOfUrl}`;

      // Redirect to the new URL
      window.location.href = newUrl;
    }
  }, [session, location]);

  return null;
};

export default RedirectHandler;
